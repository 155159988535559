@use '@/assets/sass/settings/' as *;
@use '@/assets/sass/tools/' as tools;

.navigation-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: tools.rem(90);
  width: 100vw;
  background: $color-light;
  box-shadow: 0 0 tools.rem(10) 0 $color-shadow;
  transition: height $transition;

  @include tools.respond-up-to(large) {
    &--visible {
      height: 100vh;
    }
  }

  @include tools.respond-from(large) {
    height: tools.rem(90);
    background-color: none;
  }
}

.navigation-bar__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &--navClosed {
    @include tools.respond-up-to(large) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  @include tools.respond-from(large) {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: tools.rem(90);
  }
}

.navigation-bar__logo {
  height: tools.rem(60);
  color: $color-primary;
  transition: color $transition;

  &:hover {
    color: $color-secondary;
  }

  &--navOpen {
    @include tools.respond-up-to(large) {
      height: tools.rem(80);
      margin-top: tools.rem(-50);
      margin-bottom: tools.rem(50);
    }
  }
}

.navigation-bar__nav {
  position: absolute;
  width: 100%;
  height: 100vh;

  @include tools.respond-up-to(large) {
    &--invisible {
      display: none;
    }
  }
}

.navigation-bar__toggle {
  position: absolute;
  top: tools.rem(27);
  right: tools.rem(20);

  @include tools.respond-from(large) {
    display: none;
  }
}
