@use '@/assets/sass/settings/' as *;
@use '@/assets/sass/tools/' as tools;

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
  font-family: $font-family-title;
}
