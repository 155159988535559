@use '@/assets/sass/settings/' as *;
@use '@/assets/sass/tools/' as tools;

.navigation__list {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tools.respond-from(large) {
    flex-direction: row;
  }
}

.navigation__list__item {
  list-style-type: none;
  margin: 0 tools.rem(15);
}
